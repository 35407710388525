import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://admin.sungirls.org/api' : 'http://localhost:1337/api';

const API_TOKEN = process.env.REACT_APP_STRAPI_API_TOKEN || 'your-default-token';
const headers = {
  Authorization: `Bearer ${API_TOKEN}`,
};

export const fetchBlogs = async () => {
  try {
    const response = await axios.get(`${API_URL}/blogs`, { headers });
    return response.data.data; // Access the nested data
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

export const fetchBlogById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/blogs/${id}?populate=*`, { headers });
    return response.data.data; // Access the nested data
  } catch (error) {
    console.error('Error fetching blog:', error);
    throw error;
  }
};

export const fetchEvents = async () => {
  try {
    const response = await axios.get(`${API_URL}/events?populate=media`, { headers });
    return response.data.data; // Access the nested data
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};
