import React from 'react'
import './style.css'

const CounterSection = (props) => {
    return(
        <div className={`tp-counter-area ${props.countclass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tp-counter-grids">
                            <div className="grid">
                                <div>
                                    <h2><span className="odometer" data-count="6200">2000</span>+</h2>
                                </div>
                                <p>Girls Educated</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span className="odometer" data-count="80">20</span>+</h2>
                                </div>
                                <p>Provinces Reached</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span className="odometer" data-count="245">100</span>+</h2>
                                </div>
                                <p>Online Classes Offered</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span className="odometer" data-count="605">200</span>+</h2>
                                </div>
                                <p>Workshops & Webinars</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CounterSection;