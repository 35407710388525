import React from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import blog1 from '../../images/blog/img-5.jpg'
import blog2 from '../../images/blog-details/author.jpg'
import blog3 from '../../images/blog-details/comments-author/img-1.jpg'
import blog4 from '../../images/blog-details/comments-author/img-2.jpg'
import blog5 from '../../images/blog-details/comments-author/img-3.jpg'
import blog6 from '../../images/blog/admin.jpg'


const BlogDetailsLeft = () => {

    const SubmitHandler = (e) =>{
        e.preventDefault()
     }
    return(
        <section className="tp-blog-single-section section-padding">
        <div className="container">
            <div className="row">
                <BlogSidebar/>
                <div className="col col-lg-8 col-12">
                    <div className="tp-tp-blog-content clearfix">
                        <div className="post">
                            <div className="entry-media">
                                <img src={blog1} alt=""/>
                            </div>
                            <ul className="entry-meta">
                                <li><Link to="/blog-details"><img src={blog6} alt=""/>  By Admin</Link></li>
                                <li><Link to="/blog-details"><i className="ti-calendar"></i> Sep 25,2021</Link></li>
                                <li><Link to="/blog-details"><i className="ti-heart"></i> 35</Link></li>
                            </ul>
                            <h2>Help the helpless who need you.</h2>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now.</p>
                            <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now.</p>
                            <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </blockquote>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now.</p>
                            <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now.</p>
                        </div>
                        <div className="tag-share clearfix">
                            <div className="tag">
                                <ul>
                                    <li><Link to="/blog-details">Women</Link></li>
                                    <li><Link to="/blog-details">Education</Link></li>
                                    <li><Link to="/blog-details">Empowerment</Link></li>
                                </ul>
                            </div>
                            <div className="share">
                                <ul>
                                    <li><Link to="/blog-details"><i className="ti-facebook"></i></Link></li>
                                    <li><Link to="/blog-details"><i className="ti-twitter-alt"></i></Link></li>
                                    <li><Link to="/blog-details"><i className="ti-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div> 
                        <div className="author-box">
                            <div className="author-avatar">
                                <Link to="/blog-details" target="_blank"><img src={blog2} alt=""/>  </Link>
                            </div>
                            <div className="author-content">
                                <Link to="/blog-details" className="author-name">Sun's Girl Organization</Link>
                                <p>It is vital for the international community, governments, and local organizations to prioritize education for Afghan girls. Providing resources, support, and opportunities for girls to learn, grow, and contribute will pave the way for a prosperous and equitable Afghanistan.</p>
                                <div className="author-btn">
                                    <Link to="/blog-details">All Post From Author</Link>
                                </div>
                            </div>
                        </div> 
                        <div className="more-posts clearfix">
                            <div className="previous-post">
                                <Link to="/blog-details">
                                    <span className="post-control-link">Previous</span>
                                </Link>
                            </div>
                            <div className="next-post">
                                <Link to="/blog-details">
                                    <span className="post-control-link">Next post</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="tp-blog-single-section wrap-even">
                        <div className="comments-area">
                            <div className="comments-section">
                                <h3 className="comments-title">Comments</h3>
                                <ol className="comments">
                                    <li className="comment even thread-even depth-1" id="comment-1">
                                        <div id="div-comment-1">
                                            <div className="comment-theme">
                                                <div className="comment-image"> <img src={blog3} alt=""/> </div>
                                            </div>
                                            <div className="comment-main-area">
                                                <div className="comment-wrapper">
                                                    <div className="comments-meta">
                                                        <h4>Jamshid Amiri <span className="comments-date">September 23,2024 At 11.00am</span></h4>
                                                    </div>
                                                    <div className="comment-area">
                                                        <p>Helpful! </p>
                                                        <div className="comments-reply">
                                                            <Link className="comment-reply-link" to="/blog-details"><i className="fa fa-reply" aria-hidden="true"></i><span>Reply</span></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="children">
                                            <li className="comment">
                                                <div>
                                                    <div className="comment-theme">
                                                        <div className="comment-image"> <img src={blog4} alt=""/></div>
                                                    </div>
                                                    <div className="comment-main-area">
                                                        <div className="comment-wrapper">
                                                            <div className="comments-meta">
                                                                <h4>Lily Watson <span className="comments-date">Octobor 08,2024 At 9.00am</span></h4>
                                                            </div>
                                                            <div className="comment-area">
                                                                <p>Inspiring and hopeful! This blog beautifully advocates for the empowerment of women and gender equality. 💪 </p>
                                                                <div className="comments-reply">
                                                                    <Link className="comment-reply-link" to="/blog-details"><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul>
                                                    <li className="comment">
                                                        <div>
                                                            <div className="comment-theme">
                                                                <div className="comment-image"><img src={blog5} alt=""/> </div>
                                                            </div>
                                                            <div className="comment-main-area">
                                                                <div className="comment-wrapper">
                                                                    <div className="comments-meta">
                                                                        <h4>Nayera Kohistani <span className="comments-date">September 18,2024 At 6.00am</span></h4>
                                                                    </div>
                                                                    <div className="comment-area">
                                                                        <p>A powerful reminder of the vital role women have played in Islamic history and the potential of Afghan girls to drive positive change through education. 🌟 </p>
                                                                        <div className="comments-reply">
                                                                            <Link className="comment-reply-link" to="/blog-details"><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div> 
                        <div className="comment-respond">
                            <h3 className="comment-reply-title">Leave a Comment</h3>
                            <form method="post" id="commentform" className="comment-form" onSubmit={SubmitHandler}>
                                <div className="form-inputs">
                                    <input placeholder="Name" type="text"/>
                                    <input placeholder="Email" type="email"/>
                                    <input placeholder="Website" type="url"/>
                                </div>
                                <div className="form-textarea">
                                    <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                                </div>
                                <div className="form-submit">
                                    <input id="submit" value="Reply" type="submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
     )
        
}

export default BlogDetailsLeft;
