import React from 'react';
import { Link } from 'react-router-dom';
import VideoModal from '../../components/ModalVideo';
import abimg from '../../images/about.jpg';
import abimg2 from '../../images/about/1.png';
import abimg3 from '../../images/about/2.png';
import abimg4 from '../../images/shape/shape2.png';
import './style.css';

const About2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="about-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12 grid col-12">
            <div className="video-area">
              <img src={abimg} alt="" className="animated fadeIn" />
              <div className="entry-media video-holder video-btn2">
                <VideoModal />
              </div>
            </div>
          </div>
          <div className="col-lg-7 grid col-md-12 col-12">
            <div className="about-text animated fadeInRight">
              <div className="section-title">
                <div className="thumb-text">
                  <span>ABOUT US</span>
                </div>
                <h2>
                  Welcome to <span>Sun’s Girls</span> Organization: <span>Empowering Afghan Girls</span> Through Education.
                </h2>
              </div>
              <p>
                At Sun’s Girls Organization, we are on a mission to empower Afghan girls by providing them with free education and essential life skills. In a country where girls are denied their basic right to education, we strive to change the narrative, one girl at a time.
              </p>
              <div className="ab-icon-area">
                <div className="about-icon-wrap">
                  <div className="about-icon-item">
                    <div className="ab-icon">
                      <img draggable="false" src={abimg2} alt="" />
                    </div>
                    <div className="ab-text">
                      <h2>
                        <Link onClick={ClickHandler} to="/causes">Empower Girls.</Link>
                      </h2>
                    </div>
                  </div>
                  <div className="about-icon-item">
                    <div className="ab-icon ab-icon2">
                      <img draggable="false" src={abimg3} alt="" />
                    </div>
                    <div className="ab-text">
                      <h2>
                        <Link onClick={ClickHandler} to="/causes">Support Education</Link>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="mission-vision-values section-padding">
          <div className="container">
            <div className="section-title text-center">
              <h2> The Journey and Vision of Sun’s Girls Organization</h2>
            </div>
            <div className="mv-container">
              <div className="mv-item">
                <div className="thumb-text">
                  <span>OUR MISSION</span>
                </div>
                <div className="mv-content">
                  <p>
                    At Sun’s Girls Organization, we believe that every girl has the right to learn, grow, and lead. Co-founded by <strong>Frohar Hamidi</strong> and <strong>Sana Kohistani</strong>, our organization works tirelessly to provide free education and empowerment opportunities for Afghan girls who are often denied access to schooling due to political and societal barriers. Our programs have touched the lives of thousands, fostering a brighter future for these girls and their communities.
                  </p>
                </div>
              </div>
              <div className="mv-item">
                <div className="thumb-text">
                  <span>OUR VALUES</span>
                </div>
                <div className="mv-content">
                  <p><ul>
                    <li><strong>Education:</strong> The foundation for freedom and opportunity.</li>
                    <li><strong>Empowerment:</strong> Building confidence and independence in girls.</li>
                    <li><strong>Equality:</strong> Every girl, regardless of her background, deserves an opportunity to learn.</li>
                    <li><strong>Resilience:</strong> Overcoming challenges and adversity to create lasting impact.</li>
                  </ul></p>
                </div>
              </div>
              <div className="mv-item">
                <div className="thumb-text">
                  <span>OUR VISION</span>
                </div>
                <div className="mv-content">
                  <p>
                    We envision a future where Afghan girls are not just educated but are also equipped with the skills to thrive in the modern world. Through education, leadership training, and vocational programs, we aim to cultivate a generation of strong, independent women who will lead their communities and create lasting change.
                  </p>
                </div>
              </div>
              <div className="mv-item">
                <div className="thumb-text">
                  <span>FOUNDING STORY</span>
                </div>
                <div className="mv-content">
                  <p>
                    The Sun’s Girls Organization was born out of a deep desire to create change in a country where girls' education is often overlooked or actively suppressed. Specially, after the Taliban’s government showed up and banned the education for girls. Co-founders Frohar Hamidi and Sana Kohistani grew up in Afghanistan and saw firsthand how young girls were systematically deprived of their right to education. Despite their personal success, they felt a responsibility to give back to their community by ensuring that future generations of Afghan girls would have access to the education they deserve. Together with a group of passionate educators and activists, they launched SGO, in 2022 which now operates in multiple provinces across the country.
                  </p>
                </div>
              </div>
              <div className="mv-item">
                <div className="thumb-text">
                  <span>FOCUS ON EDUCATION</span>
                </div>
                <div className="mv-content">
                  <p>
                    In Afghanistan, education is often a luxury that many girls cannot afford. Cultural, economic, and political barriers frequently prevent girls from accessing even the most basic education. The situation worsened after the Taliban returned to power in 2021, with strict limitations placed on girls' education and women’s rights. SGO believes that education is a fundamental human right and that educating girls is one of the most powerful ways to create long-term, positive change.
                  </p>
                </div>
              </div>
              <div className="mv-item">
                <div className="thumb-text">
                  <span>OUR IMPACT SO FAR</span>
                </div>
                <div className="mv-content">
                  <p>
                    Since its inception, SGO has provided educational and empowerment opportunities to hundreds of girls across Afghanistan. By offering free online classes, vocational training, and workshops, we have empowered girls to continue their education despite the challenges they face. Our programs have helped create a new generation of young women who are not only well-educated but also motivated to contribute to their communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ab-shape">
        <img src={abimg4} alt="" />
      </div>
    </div>
  );
};

export default About2;
