import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './style.css';
import Donate from '../../components/Donate';
import Volunteer from '../../components/Volunteer';
import customIconImage from '../../images/location-pin.png'; // Import the custom icon image
import Features from '../../components/Features'
import CounterSection from '../../components/counter'

// Custom icon
const customIcon = L.icon({
  iconUrl: customIconImage, // Use the imported image
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const OurImpact = () => {
  return (
    <section className="our-impact-section section-padding">
      <div className="container">
        <div className="thumb-text">
          <span>OUR IMPACT</span>
        </div>
        
        <div className="impact-content">
          <div className="impact-numbers">
          <h3 class="custm-h">Empowering Girls, Shaping Futures</h3>
            <h3>By the Numbers:</h3>
            <p><ul>
              <li><span role="img" aria-label="books">   📚 </span>  <strong>2,000+</strong> Girls Educated</li>
              <li><span role="img" aria-label="globe">   🌍 </span>  <strong>20</strong> Provinces Reached</li>
              <li><span role="img" aria-label="computer">   💻 </span>  <strong>100+</strong> Online Classes Offered</li>
              <li><span role="img" aria-label="tools">   🛠️ </span>  <strong>200+</strong> Vocational Skills Workshops and Webinars</li>
            </ul></p>
          </div>
          <CounterSection/>
          <div className="community-transformation">
            <h3>Community Transformation</h3>
            <p>
              Our efforts extend beyond just the girls we empower. By educating these young women, we are fostering positive change in their families and communities. Educated girls are more likely to become community leaders, start businesses, and uplift others around them.
            </p>
          </div>
          <div className="key-areas-of-impact">
            <h3>Key Areas of Impact:</h3>
            <p><ul>
              <li><strong>Increased Access to Education:</strong> More girls can attend classes, especially where formal schooling is not an option.</li>
              <li><strong>Economic Empowerment:</strong> Girls trained in vocational skills and STEM contribute financially to their households.</li>
              <li><strong>Breaking Gender Norms:</strong> Our girls are paving the way for the next generation by challenging the societal barriers that restrict women’s roles.</li>
            </ul></p>
          </div>
          <div className="interactive-map">
            <h3>Interactive Map</h3>
            <MapContainer center={[34.5553, 69.2075]} zoom={6} style={{ height: '400px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[34.5553, 69.2075]} icon={customIcon}>
                <Popup>
                  <strong>Kabul:</strong> Zahra’s Research Path, After completing the C1 English language class and learning academic writing fundamentals through SGO’s programs, Zahra Amani was able to publish her first ever research paper on an international journal.
                  <img src="path/to/zahra-image.jpg" alt="Zahra" style={{ width: '100%', marginTop: '10px' }} />
                </Popup>
              </Marker>
              <Marker position={[36.728, 68.8681]} icon={customIcon}>
                <Popup>
                  <strong>Mazar-i-Sharif:</strong> Rahia's Story, I am Rahia (pseudonym). I was in the 10th grade when the schools were closed. We have not gone to school for almost three years and I am trying to study. I got the first grade in school, but I could not participate in the courses because I have financial problems. Sun’s Girls Organization helped me to study math and English for free. My field of interest is medicine. I want to become an excellent and successful doctor to serve my country and people. I just wish that schools would open sooner
                  <img src="path/to/fatima-image.jpg" alt="Rahia" style={{ width: '50%', marginTop: '10px' }} />
                </Popup>
              </Marker>
              <Marker position={[31.5497, 65.7093]} icon={customIcon}>
                <Popup>
                  <strong>Kandahar:</strong> Amina’s Journey, One of our most inspiring success stories is Amina, a 16-year-old girl from a rural province in Afghanistan. When the Taliban imposed restrictions on girls' education, Amina's school was shut down, leaving her without access to formal education. Despite these challenges, Amina enrolled in our online classes and excelled in our STEM program. She became a top student in her class and is now teaching other girls in her village. Amina dreams of becoming an engineer and is committed to continuing her studies, thanks to the support she received from SGO.
                  <img src="path/to/aisha-image.jpg" alt="Amina" style={{ width: '100%', marginTop: '5px' }} />
                </Popup>
              </Marker>
              {/* Add more markers for other provinces */}
            </MapContainer>
          </div>
          <div className="case-studies">
            <div className="thumb-text">
          <span>Success Stories</span>
        </div>
            <div className="case-study">
              <h4>Zahra’s Research Path</h4>
              <p>"After completing the C1 English language class and learning academic writing fundamentals through SGO’s programs, Zahra Amani was able to publish her first ever research paper on an international journal."</p>
              <p class="custom-color">- Admin</p>
            </div>
            <div className="case-study">
              <h4>Rahia's Story</h4>
              <p>"I am Rahia (pseudonym). I was in the 10th grade when the schools were closed. We have not gone to school for almost three years and I am trying to study. I got the first grade in school, but I could not participate in the courses because I have financial problems. Sun’s Girls Organization helped me to study math and English for free. My field of interest is medicine. I want to become an excellent and successful doctor to serve my country and people. I just wish that schools would open sooner."</p>
              <p class="custom-color">- Admin</p>
            </div>
            <div className="case-study">
              <h4>Amina’s Journey</h4>
              <p>"One of our most inspiring success stories is Amina, a 16-year-old girl from a rural province in Afghanistan. When the Taliban imposed restrictions on girls' education, Amina's school was shut down, leaving her without access to formal education. Despite these challenges, Amina enrolled in our online classes and excelled in our STEM program. She became a top student in her class and is now teaching other girls in her village. Amina dreams of becoming an engineer and is committed to continuing her studies, thanks to the support she received from SGO."</p>
              <p class="custom-color">- Admin</p>
            </div>
            {/* Add more case studies */}
          </div>
          
          
        </div>
        
      </div><div className="call-to-action">
          
          <Features/>
            <Donate />
            <h3>Become a Volunteer</h3>
            <Volunteer />
            
          </div>
    </section>
  );
};

export default OurImpact;