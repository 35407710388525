import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import OurImpact from '../../components/OurImpact'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Causes from '../../components/Causes';
import Tesimonial from '../../components/Testimonial';


const ImpactPage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Our Impact'} pagesub={'impact'}/> 
            <OurImpact/>
            <Tesimonial/>
            <Causes/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ImpactPage;