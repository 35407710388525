import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlogs } from '../../services/strapiService';
import './style.css';
import blog4 from '../../images/blog/admin.jpg'; // Ensure this import is correct

const BlogFullwidth = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogsData = await fetchBlogs();
        setBlogs(blogsData);
        console.log('Blogs state:', blogsData);
      } catch (error) {
        setError(error);
        console.error('Error fetching blogs:', error);
      }
    };

    getBlogs();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!blogs.length) {
    return <div>Loading...</div>;
  }

  return (
    <section className="tp-blog-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-md-12">
            <div className="tp-tp-blog-content">
              {blogs.map(blog => (
                <div key={blog.id} className="post format-standard-image">
                  <div className="entry-media">
                    {blog.attributes.image && <img src={`http://localhost:1337${blog.attributes.image.url}`} alt={blog.attributes.title} />}
                  </div>
                  <ul className="entry-meta">
                    <li><Link to={`/blog-details/${blog.id}`}><img src={blog4} alt=""/> By {blog.attributes.author}</Link></li>
                    <li><Link to={`/blog-details/${blog.id}`}><i className="ti-calendar"></i> {new Date(blog.attributes.publishedAt).toLocaleDateString()}</Link></li>
                    <li><Link to={`/blog-details/${blog.id}`}><i className="ti-heart"></i> 35</Link></li>
                  </ul>
                  <h3><Link to={`/blog-details/${blog.id}`}>{blog.attributes.title}</Link></h3>
                  <p>{blog.attributes.content.map(block => block.children.map(child => child.text).join('')).join(' ').substring(0, 100)}...</p>
                  <Link to={`/blog-details/${blog.id}`} className="read-more">Read More...</Link>
                </div>
              ))}
              <div className="pagination-wrapper pagination-wrapper-left">
                <ul className="pg-pagination">
                  <li>
                    <Link to="/blog-details" aria-label="Previous">
                      <i className="fi ti-angle-left"></i>
                    </Link>
                  </li>
                  <li className="active"><Link to="/blog-details">1</Link></li>
                  <li><Link to="/blog-details">2</Link></li>
                  <li><Link to="/blog-details">3</Link></li>
                  <li><Link to="/blog-details">4</Link></li>
                  <li>
                    <Link to="/blog-details" aria-label="Next">
                      <i className="fi ti-angle-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogFullwidth;