import React from 'react'
import {Link} from 'react-router-dom'
import causesimg from '../../images/cause/img-1.png'
import causesimg2 from '../../images/cause/img-2.jpg'
import causesimg3 from '../../images/cause/img-3.jpg'
import causesimg4 from '../../images/cause/img-4.jpg'
import causesimg5 from '../../images/cause/img-5.jpg'
import causesimg6 from '../../images/cause/img-6.jpg'
import './style.css'

const Causes = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="case-area section-padding">
            <div className="container">
                <div className="col-lg-6 offset-lg-3">
                    <div className="section-title section-title2 text-center">
                        <div className="thumb-text">
                            <span>CAUSES</span>
                        </div>
                        <h2>Latest Caused of SGO.</h2>
                        <p>Promoting Girls' Education: Advocating for the basic right to education for Afghan girls in regions where they are denied access.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/donate">GOAL : $1000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/donate">RISED : $283</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/causes">STEM Education for Girls</Link></h3>
                                <p>We’ve teamed up with Code Blossom to offer free STEM courses for Afghan girls. Our STEM program provides top-notch science and technology education, empowering girls with skills for careers typically dominated by men.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg2} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/donate">GOAL : $1000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/donate">RISED : $200</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/causes">Informal online  Education</Link></h3>
                                <p>Afghan girls can’t continue their education past 7th grade. We offer free online education in subjects like Mathematics, English A1-C1, astronomy, chemistry, biology, academic writing, and ICDL, providing alternative learning opportunities.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg3} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/donate">GOAL : $1000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/donate">RISED : $200</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/causes">Vocational Skills Training</Link></h3>
                                <p>Many Afghan girls lack employment opportunities due to limited skills training. Our vocational programs include online classes in Photography and Art, helping girls develop marketable skills for better job prospects.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg4} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/donate">GOAL : $1000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/donate">RISED : $320</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/causes">Mental Health and Wellbeing</Link></h3>
                                <p>Amid trauma and uncertainty, mental health support is crucial. We provide wellness workshops that teach coping mechanisms, mindfulness, and stress management, ensuring girls receive the necessary psychological support.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg5} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/donate">GOAL : $1000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/donate">RISED : $280</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/causes">Skill Development Workshops</Link></h3>
                                <p>Alongside academics, we offer vocational training, equipping girls with practical skills to earn a living. Workshops include photography, art, and in-demand trades, fostering self-sufficiency.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg6} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="">GOAL : $1000</Link></li>
                                    <li><Link onClick={ClickHandler} to="">RISED : $251</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/causes">Health Education</Link></h3>
                                <p>Our health programs educate girls on basic hygiene, nutrition, and reproductive health, ensuring they excel academically and grow up healthy, informed, contributing to their overall well-being.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Causes;