import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchEvents } from '../../services/strapiService';
import './style.css';
import event4 from '../../images/event/1.png'; // Import event4 image
import event5 from '../../images/event/2.png'; // Import event5 image

const EventSection = (props) => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getEvents = async () => {
      try {
        const eventsData = await fetchEvents();
        setEvents(eventsData);
      } catch (error) {
        setError(error);
      }
    };

    getEvents();
  }, []);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="event-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-title section-title2 text-center">
              <div className="thumb-text">
                <span>EVENTS</span>
              </div>
              <h2>Our Upcoming Events</h2>
              <p>Join us for exciting events that empower and educate women. Stay tuned for workshops, seminars, and community programs designed to make a difference. </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {Array.isArray(events) && events.map(event => (
              <div key={event.id} className="event-item">
                <div className="event-img">
                  {event.attributes.image && <img src={`http://localhost:1337${event.attributes.image.data.attributes.url}`} alt={event.attributes.name} />}
                </div>
                <div className="event-text">
                  <div className="event-left">
                    <div className="event-l-text">
                      <span>{new Date(event.attributes.date).toLocaleString('default', { month: 'short' }).toUpperCase()}</span>
                      <h4>{new Date(event.attributes.date).getDate()}</h4>
                    </div>
                  </div>
                  <div className="event-right">
                    <ul>
                      <li><i className="ti-time"></i> {event.attributes.time}</li>
                      <li><i className="ti-location-pin"></i> {event.attributes.location}</li>
                    </ul>
                    <h2><Link onClick={ClickHandler} to={`/event/${event.id}`}>{event.attributes.name}</Link></h2>
                    <p>{event.attributes.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="shape1"><img src={event4} alt="" /></div>
      <div className="shape2"><img src={event5} alt="" /></div>
    </div>
  );
};

export default EventSection;