import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import BlogFullwidth from '../../components/BlogFullwidth';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const BlogPageFullwidth = () => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={'Latest News'} pagesub={'Blog'} />
      <BlogFullwidth />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default BlogPageFullwidth;