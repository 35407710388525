import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlogs } from '../../services/strapiService';
import './style.css';

const BlogSidebar = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getRecentPosts = async () => {
      try {
        const blogsData = await fetchBlogs();
        setRecentPosts(blogsData);
        console.log('Recent posts state:', blogsData);
      } catch (error) {
        setError(error);
        console.error('Error fetching recent posts:', error);
      }
    };

    getRecentPosts();
  }, []);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="col col-lg-4 col-12">
      <div className="tp-blog-sidebar">
        <div className="widget search-widget">
          <h3>Search Here</h3>
          <form onSubmit={SubmitHandler}>
            <div>
              <input type="text" className="form-control" placeholder="Search Post.." />
              <button type="submit"><i className="ti-search"></i></button>
            </div>
          </form>
        </div>
        <div className="widget category-widget">
          <h3>Categories</h3>
          <ul>
            <li><Link to="/blog-details-fullwidth">Education</Link></li>
            <li><Link to="/blog-details-fullwidth">Empowerment</Link></li>
            <li><Link to="/blog-details-fullwidth">Charity</Link></li>
            <li><Link to="/blog-details-fullwidth">Medical Campaign</Link></li>
            <li><Link to="/blog-details-fullwidth">Women</Link></li>
          </ul>
        </div>
        <div className="widget recent-post-widget">
          <h3>Recent posts</h3>
          <div className="posts">
            {recentPosts.map(post => (
              <div key={post.id} className="post">
                <div className="img-holder">
                  {post.attributes.image && <img src={`http://localhost:1337${post.attributes.image.url}`} alt={post.attributes.title} />}
                </div>
                <div className="details">
                  <h4><Link to={`/blog-details/${post.id}`}>{post.attributes.title}</Link></h4>
                  <span className="date">{new Date(post.attributes.publishedAt).toLocaleDateString()}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="widget tag-widget">
          <h3>Tags</h3>
          <ul>
            <li><Link to="/blog-details-fullwidth">Women’s education</Link></li>
            <li><Link to="/blog-details-fullwidth">Empowering women</Link></li>
            <li><Link to="/blog-details-fullwidth">Women’s leadership and education</Link></li>
            <li><Link to="/blog-details-fullwidth">Non Profit</Link></li>
            <li><Link to="/blog-details-fullwidth">Donation</Link></li>
            <li><Link to="/blog-details-fullwidth">Women’s health</Link></li>
            <li><Link to="/blog-details-fullwidth">Gender equality</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;