import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../HomePage';
import HomePage2 from '../HomePage2';
/*import BlogPage from '../BlogPage';*/
import BlogDetails from '../BlogDetails';
import ErrorPage from '../ErrorPage';
import ContactPage from '../ContactPage';
import LoginPage from '../LoginPage';
import SignUpPage from '../SignUpPage';
import ForgotPassword from '../ForgotPassword';
import BlogList from '../../components/BlogList';
import EventSection from '../../components/EventSection';
import DonatePage from '../DonatePage';
import CausePage from '../CausePage';
import EventPage from '../EventPage';
import AboutPage from '../AboutPage';
import VolunteerPage from '../volunteerPage';
import BlogPageFullwidth from '../BlogPageFullwidth';
import BlogDetailsFull from '../BlogDetailsFull';
import BlogDetailsLeft from '../BlogDetailsLeftSiide';
import ImpactPage from '../ImpactPage';

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route exact path="/" element={<HomePage />} />
          <Route path="/home2" element={<HomePage2 />} />
          <Route path="/blogs" element={<BlogDetailsFull />} />
          <Route path="/blog-details/:id" element={<BlogDetails />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/blog-list" element={<BlogList />} />
          <Route path="/event-section" element={<EventSection />} />
          <Route path="/donate" element={<DonatePage />} />
          <Route path="/causes" element={<CausePage />} />
          <Route path="/event" element={<EventPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/volunteer" element={<VolunteerPage />} />
          <Route path="/blog-fullwidth" element={<BlogPageFullwidth />} />
          <Route path='/blog-details-fullwidth' element={<BlogDetailsFull/>}/>
          <Route path='/blog-details-left' element={<BlogDetailsLeft/>}/>
          <Route path='/impact' element={<ImpactPage/>}/>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AllRoute;