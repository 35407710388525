import React from 'react';

const PrevArrow = ({ currentSlide, slideCount, ...props }) => {
  return (
    <button type="button" {...props} className="slick-prev">
      <i className="fa fa-angle-double-left"></i>
    </button>
  );
};

const NextArrow = ({ currentSlide, slideCount, ...props }) => {
  return (
    <button type="button" {...props} className="slick-next">
      <i className="fa fa-angle-double-right"></i>
    </button>
  );
};

export { PrevArrow, NextArrow };