import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlogs } from '../../services/strapiService';
import './style.css';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogsData = await fetchBlogs();
        console.log('Fetched blogs:', blogsData); // Debugging statement
        setBlogs(blogsData);
      } catch (error) {
        setError(error);
        console.error('Error fetching blogs:', error);
      }
    };

    getBlogs();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      {blogs.length > 0 ? (
        blogs.map(blog => (
          <div key={blog.id}>
            <ul>
              <li><Link to={`/blog-details/${blog.id}`}><i className="ti-heart"></i> 35</Link></li>
            </ul>
            <h3><Link to={`/blog-details/${blog.id}`}>{blog.attributes.title}</Link></h3>
            <p>{blog.attributes.content.substring(0, 100)}...</p>
            <Link to={`/blog-details/${blog.id}`} className="read-more">Read More...</Link>
          </div>
        ))
      ) : (
        <p>No blogs available</p>
      )}
      <div className="pagination-wrapper pagination-wrapper-left">
        <ul className="pg-pagination">
          <li>
            <Link to="/blog-details" aria-label="Previous">
              <i className="fi ti-angle-left"></i>
            </Link>
          </li>
          <li className="active"><Link to="/blog-details">1</Link></li>
          <li><Link to="/blog-details">2</Link></li>
          <li><Link to="/blog-details">3</Link></li>
          <li><Link to="/blog-details">4</Link></li>
          <li>
            <Link to="/blog-details" aria-label="Next">
              <i className="fi ti-angle-right"></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogList;