import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchBlogById } from '../../services/strapiService';
import './style.css';
import blog2 from '../../images/blog-details/author.jpg';
import blog3 from '../../images/blog-details/comments-author/img-1.jpg';
import blog4 from '../../images/blog-details/comments-author/img-2.jpg';
import blog5 from '../../images/blog-details/comments-author/img-3.jpg';
import BlogSidebar from '../BlogSidebar';

const BlogSingle = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBlog = async () => {
      try {
        const blogData = await fetchBlogById(id);
        setBlog(blogData);
        console.log('Blog state:', blogData);
      } catch (error) {
        setError(error);
        console.error('Error fetching blog:', error);
      }
    };

    getBlog();
  }, [id]);

  const renderContent = (content) => {
    return content.map((block, index) => {
      switch (block.type) {
        case 'paragraph':
          return <p key={index}>{block.children.map(child => child.text).join('')}</p>;
        case 'heading':
          return React.createElement(`h${block.level}`, { key: index }, block.children.map(child => child.text).join(''));
        case 'image':
          return <img key={index} src={block.image.url} alt={block.image.alternativeText} />;
        case 'quote':
          return <blockquote key={index}>{block.children.map(child => child.text).join('')}</blockquote>;
        default:
          return null;
      }
    });
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <section className="tp-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-8 col-12">
            <div className="tp-tp-blog-content clearfix">
              <div className="post">
                <div className="entry-media">
                  {blog.attributes.image && <img src={`http://localhost:1337${blog.attributes.image.url}`} alt={blog.attributes.title} />}
                </div>
                <ul className="entry-meta">
                  <li><img src={blog2} alt=""/> By {blog.attributes.author}</li>
                  <li>{new Date(blog.attributes.publishedAt).toLocaleDateString()}</li>
                  <li><i className="ti-heart"></i> 35</li>
                </ul>
                <h2>{blog.attributes.title}</h2>
                {renderContent(blog.attributes.content)}
                <div className="tag-share clearfix">
                  <div className="tag">
                    <ul>
                      <li><Link to={`/blog-details/${blog.id}`}>Education</Link></li>
                      <li><Link to={`/blog-details/${blog.id}`}>Empowerment</Link></li>
                      <li><Link to={`/blog-details/${blog.id}`}>Women</Link></li>
                    </ul>
                  </div>
                  <div className="share">
                    <ul>
                      <li><Link to={`/blog-details/${blog.id}`}><i className="ti-facebook"></i></Link></li>
                      <li><Link to={`/blog-details/${blog.id}`}><i className="ti-twitter-alt"></i></Link></li>
                      <li><Link to={`/blog-details/${blog.id}`}><i className="ti-instagram"></i></Link></li>
                    </ul>
                  </div>
                </div>
                <div className="author-box">
                  <div className="author-avatar">
                    <Link to={`/blog-details/${blog.id}`} target="_blank"><img src={blog2} alt=""/>  </Link>
                  </div>
                  <div className="author-content">
                    <Link to={`/blog-details/${blog.id}`} className="author-name">Sun's Girl Organization</Link>
                    <p>It is vital for the international community, governments, and local organizations to prioritize education for Afghan girls. Providing resources, support, and opportunities for girls to learn, grow, and contribute will pave the way for a prosperous and equitable Afghanistan.</p>
                    <div className="author-btn">
                      <Link to={`/blog-details/${blog.id}`}>All Post From Author</Link>
                    </div>
                  </div>
                </div>
                <div className="more-posts clearfix">
                  <div className="previous-post">
                    <Link to={`/blog-details/${blog.id}`}>
                      <span className="post-control-link">Previous</span>
                    </Link>
                  </div>
                  <div className="next-post">
                    <Link to={`/blog-details/${blog.id}`}>
                      <span className="post-control-link">Next post</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="tp-blog-single-section wrap-even">
                <div className="comments-area">
                  <div className="comments-section">
                    <h3 className="comments-title">Comments</h3>
                    <ol className="comments">
                      <li className="comment even thread-even depth-1" id="comment-1">
                        <div id="div-comment-1">
                          <div className="comment-theme">
                            <div className="comment-image"> <img src={blog3} alt=""/> </div>
                          </div>
                          <div className="comment-main-area">
                            <div className="comment-wrapper">
                              <div className="comments-meta">
                                <h4>John Abraham <span className="comments-date">October 28, 2018 At 9.00am</span></h4>
                              </div>
                              <div className="comment-area">
                                <p>I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, </p>
                                <div className="comments-reply">
                                  <Link className="comment-reply-link" to={`/blog-details/${blog.id}`}><i className="fa fa-reply" aria-hidden="true"></i><span>Reply</span></Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul className="children">
                          <li className="comment">
                            <div>
                              <div className="comment-theme">
                                <div className="comment-image"> <img src={blog4} alt=""/></div>
                              </div>
                              <div className="comment-main-area">
                                <div className="comment-wrapper">
                                  <div className="comments-meta">
                                    <h4>Lily Watson <span className="comments-date">October 28, 2018 At 9.00am</span></h4>
                                  </div>
                                  <div className="comment-area">
                                    <p>I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, </p>
                                    <div className="comments-reply">
                                      <Link className="comment-reply-link" to={`/blog-details/${blog.id}`}><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul>
                              <li className="comment">
                                <div>
                                  <div className="comment-theme">
                                    <div className="comment-image"><img src={blog5} alt=""/> </div>
                                  </div>
                                  <div className="comment-main-area">
                                    <div className="comment-wrapper">
                                      <div className="comments-meta">
                                        <h4>John Abraham <span className="comments-date">October 28, 2018 At 9.00am</span></h4>
                                      </div>
                                      <div className="comment-area">
                                        <p>I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, </p>
                                        <div className="comments-reply">
                                          <Link className="comment-reply-link" to={`/blog-details/${blog.id}`}><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="comment-respond">
                  <h3 className="comment-reply-title">Leave a Comment</h3>
                  <form method="post" id="commentform" className="comment-form" onSubmit={SubmitHandler}>
                    <div className="form-inputs">
                      <input placeholder="Name" type="text"/>
                      <input placeholder="Email" type="email"/>
                      
                    </div>
                    <div className="form-textarea">
                      <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                    </div>
                    <div className="form-submit">
                      <input id="submit" value="Reply" type="submit"/>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <BlogSidebar/>
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;