import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import tm1 from '../../images/team/1.png';
import tm2 from '../../images/team/2.png';
import tm3 from '../../images/team/3.png';
import tm4 from '../../images/team/4.png';
import tm5 from '../../images/team/5.png'; // New image
import tm6 from '../../images/team/6.png'; // New image
import tm7 from '../../images/team/7.png'; // New image
import tm8 from '../../images/team/8.png'; // New image
import tm9 from '../../images/team/9.png'; // New image
import tm10 from '../../images/team/10.png'; // New image


const TeamSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className={`volunteer-area section-padding ${props.addclass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-title section-title2 text-center">
                            <div className="thumb-text">
                                <span>Volunteer</span>
                            </div>
                            <h2>Our Great Volunteer</h2>
                            <p>Our volunteers are the heart of our organization. Their dedication and passion drive our mission forward, making a real difference in the lives of women through education and empowerment. Join us and be part of this incredible journey! </p>
                        </div>
                    </div>
                </div>
                <div className="volunteer-wrap">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm1} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Setayesh Hamidi</Link></h2>
                                    <span>English Teacher</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm2} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Zahra Kohistani</Link></h2>
                                    <span>Chemistry Teacher</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm3} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Fariba Babaei</Link></h2>
                                    <span>Literacy Teacher</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm4} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Marwa Sadat</Link></h2>
                                    <span>Math Instructor</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm5} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Zarafshan Moradi</Link></h2>
                                    <span>Social Media Admin</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm6} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Mona Kohistani</Link></h2>
                                    <span>Physics Teacher</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm7} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Marwa Hashimi </Link></h2>
                                    <span>Webinar Announcer </span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm8} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Rabeha Omari</Link></h2>
                                    <span>English Instructor</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm9} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Micheal Driscoll</Link></h2>
                                    <span>English Instructor</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 custom-grid col-12">
                            <div className="volunteer-item">
                                <div className="volunteer-img">
                                    <img src={tm10} alt="" />
                                </div>
                                <div className="volunteer-content">
                                    <h2><Link onClick={ClickHandler} to="/volunteer">Sofia Hussaini</Link></h2>
                                    <span>English Instructor</span>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamSection;