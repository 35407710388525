import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlogs } from '../../services/strapiService';
import './style.css';

const BlogSection = (props) => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogsData = await fetchBlogs();
        setBlogs(blogsData);
        console.log('Blogs state:', blogsData);
      } catch (error) {
        setError(error);
        console.error('Error fetching blogs:', error);
      }
    };

    getBlogs();
  }, []);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="blog-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-title section-title2 text-center">
              <div className="thumb-text">
                <span>Blog</span>
              </div>
              <h2>Our Latest News</h2>
              <p>Discover the latest news and stories from our organization. We strive to keep you informed and engaged with our ongoing efforts to empower women through education and skill development. Stay tuned for inspiring updates, impactful projects, and success stories that highlight the difference we are making together.</p>
            </div>
          </div>
        </div>
        <div className="blog-wrap">
          <div className="row">
            {blogs.length > 0 ? (
              blogs.map(blog => (
                <div key={blog.id} className="col col-lg-6 col-12">
                  <div className="blog-item">
                    <div className="blog-img">
                      {blog.attributes.image && <img src={`http://localhost:1337${blog.attributes.image.url}`} alt={blog.attributes.title} />}
                    </div>
                    <div className="blog-content">
                      <ul>
                        <li>{new Date(blog.attributes.publishedAt).toLocaleDateString()}</li>
                        <li><i className="fa fa-heart" aria-hidden="true"></i>56</li>
                        <li><i className="fa fa-comments-o" aria-hidden="true"></i> 78</li>
                      </ul>
                      <h2><Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>{blog.attributes.title}</Link></h2>
                      <p>{blog.attributes.content ? blog.attributes.content.map(block => block.children.map(child => child.text).join('')).join(' ').substring(0, 100) : ''}...</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No blogs available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;