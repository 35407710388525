import React, { useState } from 'react'; // Removed useEffect
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FaCreditCard, FaPaypal } from 'react-icons/fa';
import './style.css';

console.log('Stripe Key:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

const Donate = () => {
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const stripe = useStripe();
  const elements = useElements();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleCardPayment = async (formData) => {
    setIsLoading(true);
    try {
      const { data: { clientSecret } } = await axios.post('https://api.sungirls.org/create-payment-intent', {
        amount: amount * 100,
      });

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${formData.fname} ${formData.lname}`,
            email: formData.email,
            address: {
              line1: formData.address,
            },
          },
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          setSuccessMessage('Donation successful!');
          setAmount('');
          sendDonationReceipt(result.paymentIntent);
        }
      }
    } catch (error) {
      setError('Payment failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const sendDonationReceipt = (paymentDetails) => {
    // Implement email sending logic here
    console.log('Sending donation receipt:', paymentDetails);
  };

  const onSubmit = async (formData) => {
    if (paymentMethod === 'paypal') {
      // Temporarily disable PayPal functionality
      // const paypalButton = document.getElementById('paypal-button-container');
      // paypalButton.style.display = 'block';
      setError('PayPal functionality is temporarily disabled.');
    } else {
      await handleCardPayment(formData);
    }
  };

  return (
    <div className="tp-donation-page-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="tp-donate-header">
              <h2>Make a Donation</h2>
            </div>
            <div id="Donations">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="tp-donations-amount">
                  <h2>Your Donation</h2>
                  <input
                    type="number"
                    className="form-control"
                    name="amount"
                    id="amount"
                    placeholder="Enter Donation Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                  />
                </div>
                <div className="tp-donations-details">
                  <h2>Details</h2>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="fname"
                        id="fname"
                        placeholder="First Name"
                        {...register('fname', { required: true })}
                        required
                      />
                      {errors.fname && <p className="error-message">First name is required</p>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="lname"
                        id="lname"
                        placeholder="Last Name"
                        {...register('lname', { required: true })}
                        required
                      />
                      {errors.lname && <p className="error-message">Last name is required</p>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email"
                        {...register('email', { required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
                        required
                      />
                      {errors.email && <p className="error-message">Invalid email address</p>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        id="address"
                        placeholder="Address"
                        {...register('address', { required: true })}
                        required
                      />
                      {errors.address && <p className="error-message">Address is required</p>}
                    </div>
                    <div className="col-lg-12 col-12 form-group">
                      <textarea
                        className="form-control"
                        name="note"
                        id="note"
                        placeholder="Message"
                        {...register('note')}
                      />
                    </div>
                  </div>
                </div>
                <div className="tp-doanation-payment">
                  <h2>Choose Your Payment Method</h2>
                  <div className="tp-payment-area">
                    <div className="row">
                      <div className="col-12">
                        <div className="tp-payment-option" id="open4">
                          <div className="tp-payment-select">
                            <ul>
                              <li className="addToggle">
                                <input
                                  id="card"
                                  type="radio"
                                  name="payment"
                                  value="card"
                                  checked={paymentMethod === 'card'}
                                  onChange={() => setPaymentMethod('card')}
                                />
                                <label htmlFor="card">
                                  <FaCreditCard /> Payment By Card
                                </label>
                              </li>
                              <li>
                                <input
                                  id="paypal"
                                  type="radio"
                                  name="payment"
                                  value="paypal"
                                  checked={paymentMethod === 'paypal'}
                                  onChange={() => setPaymentMethod('paypal')}
                                />
                                <label htmlFor="paypal">
                                  <FaPaypal /> PayPal
                                </label>
                              </li>
                            </ul>
                          </div>
                          <div id="open5" className="payment-name">
                            {paymentMethod === 'card' && (
                              <div className="contact-form form-style">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 col-12">
                                    <label>Card Details</label>
                                    <CardElement />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-area sub-btn">
                  <button type="submit" className="theme-btn submit-btn" disabled={isLoading}>
                    {isLoading ? 'Processing...' : 'Donate Now'}
                  </button>
                </div>
                {error && <p className="error-message">{error}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
                {/* Temporarily disable PayPal functionality */}
                {/* <div id="paypal-button-container" style={{ display: paymentMethod === 'paypal' ? 'block' : 'none' }}>
                  <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                      style={{
                        shape: "rect",
                        layout: "vertical",
                      }}
                      createOrder={async () => {
                        try {
                          const response = await fetch("/api/donations", {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              amount: amount,
                            }),
                          });

                          const orderData = await response.json();

                          if (orderData.id) {
                            return orderData.id;
                          } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail
                              ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                              : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                          }
                        } catch (error) {
                          console.error(error);
                          setError(`Could not initiate PayPal Checkout...${error}`);
                        }
                      }}
                      onApprove={async (data, actions) => {
                        try {
                          const response = await fetch(
                            `/api/donations/${data.orderID}/capture`,
                            {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                            },
                          );

                          const orderData = await response.json();
                          const errorDetail = orderData?.details?.[0];

                          if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                            return actions.restart();
                          } else if (errorDetail) {
                            throw new Error(
                              `${errorDetail.description} (${orderData.debug_id})`,
                            );
                          } else {
                            const transaction =
                              orderData.purchase_units[0].payments.captures[0];
                            setSuccessMessage(
                              `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                            );
                            console.log(
                              "Capture result",
                              orderData,
                              JSON.stringify(orderData, null, 2),
                            );
                          }
                        } catch (error) {
                          console.error(error);
                          setError(
                            `Sorry, your transaction could not be processed...${error}`,
                          );
                        }
                      }}
                    />
                  </PayPalScriptProvider>
                </div> */}
              </form>
              <div className="gofundme-link">
                <h2>Or Donate via GoFundMe</h2>
                <a href="https://gofund.me/093a4a10" target="_blank" rel="noopener noreferrer" className="theme-btn gofundme-btn">Go to GoFundMe</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedDonate = () => (
  <Elements stripe={stripePromise}>
    <Donate />
  </Elements>
);

export default WrappedDonate;
