import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo/logo-b.png'
import './style.css'

const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className="tp-site-footer">
            <div className="tp-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="footer-logo widget-title">
                                    <Link onClick={ClickHandler} to=""><img src={Logo} alt="logo" />S<span>
                                        <span className="color1">G</span>
                                        <span className="color2">O</span>
                                    </span></Link>
                                </div>
                                <p>Join us in empowering the next generation of Afghan women. </p>
                                <ul>
                                <li><a href="https://wa.me/93749221870" target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp fa-lg"></i></a></li>
                                <li><a href="https://twitter.com/your-profile" target="_blank" rel="noopener noreferrer"><i className="ti-twitter-alt"></i></a></li>
                                <li><a href="https://www.instagram.com/suns_girls_organization?igshid=MTJvM3RjdjdqaWhoMQ&utm_source=qr" target="_blank" rel="noopener noreferrer"><i className="ti-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/sun-s-girls-organization/" target="_blank" rel="noopener noreferrer"><i className="ti-linkedin"></i></a></li>

                                  </ul>
                            </div>
                        </div>
                        <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/causes">Our Causes</Link></li>
                                    <li><Link onClick={ClickHandler} to="/volunteer">Our Volunteer</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/event">Our Event</Link></li>
                                    <li><Link onClick={ClickHandler} to="/impact">Our Impact</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-lg-offset-1 col-md-6 col-sm-12 col-12">
                            <div className="widget market-widget tp-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact </h3>
                                </div>
                                <p>For more information please contact us through the following channels:</p>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-pin"></i>Afghanistan</li>
                                        <li><i className="fi flaticon-call"></i>+93 74 922 1870</li>
                                        <li><i className="fi flaticon-envelope"></i>sungirl.info@gmail.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget newsletter">
                                <div className="widget-title">
                                    <h3>Newsletter</h3>
                                </div>
                                <p>Subscribe to our newsletter to get the latest updates and news.</p>
                                <form className="newsletter-form">
                                    <input type="email" placeholder="Enter your email" required />
                                    <button type="submit">Subscribe</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tp-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright">&copy; 2024 Sun's Girl Organization (SGO). All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;